/*eslint eqeqeq:0*/
/*eslint no-mixed-operators:0*/
import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";

//new
import { toast } from "react-toastify";
import getOneMessageDin from "../../hooks/getOneMessageDin";
import confirmCodTipica from "../../hooks/confirmCodTipica";
import confirmCodfile from "../../hooks/confirmCodfile";
import getTipicAntContact from "../../hooks/getTipicAntContact";
import getMessageDin from "../../hooks/getMessageDin";
//fin new


const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  },
}));

const MessageInput = ({ ticketStatus }) => {
  const classes = useStyles();
  const { ticketId } = useParams();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [useMic,] = useLocalStorage("useMic", true);
  const [useAttach,] = useLocalStorage("useAttach", true);
  
  //console.log("useAttach en message input: ", useAttach);
  

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  /* const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  }; */
  
  const handleInputPaste = (e) => {
	if (useAttach) {
		if (e.clipboardData.files[0]) {
		  setMedias([e.clipboardData.files[0]]);
		}
	} else {
		 setMedias([]);
	}
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
		//new
		
		if(inputMessage.trim() === "~?"){
			var arr = await getMessageDin();
			var envioList = 0;
			var msgs2 = [];
			for(var i = 0; i < arr.length; i++)
			{
				if ((i % 10) === 0 && i > 0){
					msgs2.push(<div>{arr[i].codmensaje + " -> " + arr[i].mensaje}<br />  </div>);
					envioList = 0;
					toast.success(<div>{msgs2}<br /></div>, {
						position: "top-center",
						autoClose: 10000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						enableHtml: true,
						allowHtml: true,
						width: "20px"
					});
					msgs2 = [];
				}
				else {
					msgs2.push(<div>{arr[i].codmensaje + " -> " + arr[i].mensaje}<br />  </div>);
					envioList = 1;
				}
			  
			}
			if(envioList === 1){
				toast.success(<div>{msgs2}<br /></div>, {
					position: "top-center",
					autoClose: 25000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					enableHtml: true,
					allowHtml: true,
					width: "20px"
				});
			}
			setLoading(true);
			setInputMessage("");
			setLoading(false);
			return;
		}
		var idx = inputMessage.trim().indexOf('~');
		var msgenviar = "";
		var encontro = 0;
		var posshort = 0;
		var shorcode = "";
		var oldPosShort = 0;
		while (idx !== -1) {		
			  posshort = idx +1;
			  shorcode = "";
			  while (posshort < inputMessage.trim().length && inputMessage.trim().at(posshort) !== " "){
				  shorcode = shorcode + inputMessage.trim().at(posshort);
				  posshort++;	  
			  }
			  if (shorcode.length > 0){
				  var msgDinamic = await getOneMessageDin(shorcode);
				  if ( msgDinamic.id > 0){
					  encontro = 1;
					  msgenviar = msgenviar + inputMessage.trim().substr(oldPosShort,idx - oldPosShort) + " "  +  msgDinamic.mensaje;   
				  }
				  else {
					  encontro = 1;
					  msgenviar = msgenviar + " -- ";
				  }
			  }
				oldPosShort = idx + shorcode.length + 1;
				idx = inputMessage.trim().indexOf('~', idx + posshort);
		}
		if(encontro === 1){
			setLoading(true);
			setInputMessage(msgenviar); 
			setReplyingMessage(null);
			setShowEmoji(false);
			setLoading(false);
			return;
		}
		//|?
		if(inputMessage.trim() === "|?"){
			var arr3 = await getTipicAntContact(ticketId); //retona // Array() [ {…}, {…}, {…}, … ] un arreglo de objetos
			var envioList3 = 0;
			var msgs3 = [];
			var i2 = 0;
			const DATE_FORMATER = require( 'dateformat' );
			arr3.forEach((arr21) => {
				if ((i2 % 9) === 0 && i2 > 0){
					msgs3.push(<div>{"(" + arr21.codigo + ") " + arr21.datos + " -> " + DATE_FORMATER(arr21.createdAt, "yyyy-mm-dd HH:MM:ss")}<br />  </div>);
					envioList3 = 0;
					toast.success(<div>{msgs3}<br /></div>, {
						position: "top-center",
						autoClose: 10000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						enableHtml: true,
						allowHtml: true,
						width: "800px"
					});
					msgs3 = [];
				}
				else {
					msgs3.push(<div>{"(" + arr21.codigo + ") " + arr21.datos + " -> " + DATE_FORMATER(arr21.createdAt, "yyyy-mm-dd HH:MM:ss")}<br />  </div>);
					envioList3 = 1;
				}
				i2++;
			});
			if(envioList3 === 1){
				toast.success(<div>{msgs3}<br /></div>, {
					position: "top-center",
					autoClose: 25000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					enableHtml: true,
					allowHtml: true,
					width: "20px"
				});
			}
			setLoading(true);
			setInputMessage("");
			setLoading(false);
			return;
		}
		//|?
		// retornar al Bot es =>
		/* if(inputMessage.startsWith("!=>")){
			setLoading(true);
			try {
				await api.put(`/ticketsrb/${ticketId}`, {
				destino: inputMessage.trim(),
				//queueId: null,
				//status: "open",
			});
			} catch (err) {
				toastError(err);
			}
			setInputMessage(""); 
			setReplyingMessage(null);
			setShowEmoji(false);
			setLoading(false);
			return;
		} */
		if(inputMessage.trim() === "!desatel"){
			setLoading(true);
					   const message4 = {
						read: 1,
						fromMe: true,
						mediaUrl: "",
						body: `*${user?.name}:*\nwb1167b-returnBot`
						};
						try {
							await api.post(`/messagescomentnt/${ticketId}`, message4);
						} catch (err) {
							toastError(err);
						}
					  setInputMessage(""); 
						setReplyingMessage(null);
						setShowEmoji(false);
						setLoading(false);
						return;
		}
		// comentario es !
		if(inputMessage.trim().charAt(0) === "!"){
			setLoading(true);
			//comentario para todos los tickets que esten en conferencia
			let idUuid = uuidv4();
			let { data } = await api.get("/tickets/" + ticketId);
			const data5  = await api.get(`/MensajesC/showTicketsxContactIDAuth/${data.contact.id}`);
				data5.data.forEach(async t => {
					let message2 = {
					read: 1,
					idUuid: idUuid,
					fromMe: true,
					mediaUrl: "",
					body: `*${user?.name}:*\n${inputMessage.trim()}`
					};
					try {
						await api.post(`/messagescomentuid/${t.id}`, message2);
					} catch (err) {
						toastError(err);
					}
					
				});
			//fin comentario para todos los tickets que esten en conferencia
			/* const message2 = {
				read: 1,
				fromMe: true,
				mediaUrl: "",
				body: `*${user?.name}:*\n${inputMessage.trim()}`
				};
				try {
					await api.post(`/messagescoment/${ticketId}`, message2);
				} catch (err) {
					toastError(err);
				} */
			setInputMessage(""); 
			setReplyingMessage(null);
			setShowEmoji(false);
			setLoading(false);
			return;
		}
		//tipificacion es |
		if(inputMessage.trim().charAt(0) === "|"){
			var idx2 = inputMessage.trim().indexOf('|');
			var codTip = inputMessage.trim().substr(idx2+1,inputMessage.trim().length);
			var existCodTipi = await confirmCodTipica(codTip);
			//console.log("--- user.id:", user.id);
			//let userId = user.id;
			
				  if ( existCodTipi.id === 1){
					  setLoading(true);
						const message2 = {
							read: codTip,
							fromMe: true,
							mediaUrl: "",
							user: user.id,
							body: `*${user?.name}:*\n${inputMessage.trim()}`
							};
							try {
								await api.post(`/messagestipifica/${ticketId}`, message2);
							} catch (err) {
								toastError(err);
							}
						const message3 = {
						read: 1,
						fromMe: true,
						mediaUrl: "",
						body: `*${user?.name}:*\n${inputMessage.trim()} ✅`
						};
						try {
							await api.post(`/messagescomentnt/${ticketId}`, message3);
						} catch (err) {
							toastError(err);
						}
						setInputMessage(""); 
						setReplyingMessage(null);
						setShowEmoji(false);
						setLoading(false);
						return;  
				  } else{
					  setLoading(true);
					   const message4 = {
						read: 1,
						fromMe: true,
						mediaUrl: "",
						body: `*${user?.name}:*\n${inputMessage.trim()} ❌`
						};
						try {
							await api.post(`/messagescomentnt/${ticketId}`, message4);
						} catch (err) {
							toastError(err);
						}
					  setInputMessage(""); 
						setReplyingMessage(null);
						setShowEmoji(false);
						setLoading(false);
						return;
				  }
			
		}
		//
		//Enviar imagen es & 
		if(inputMessage.trim().charAt(0) === "&"){
			var idx3 = inputMessage.trim().indexOf('&');
			var codFile = inputMessage.trim().substr(idx3+1,inputMessage.trim().length);
			var existCodFile = await confirmCodfile(codFile);
				  if ( existCodFile.id === 1){
					  setLoading(true);
						const message = {
							read: 1,
							fromMe: true,
							mediaUrl: "",
							body: existCodFile.url,
							//quotedMsg: replyingMessage,
						};
						try {
							await api.post(`/messagesfile/${ticketId}`, message);
						} catch (err) {
							toastError(err);
						}
						const message3 = {
						read: 1,
						fromMe: true,
						mediaUrl: "",
						body: `*${user?.name}:*\n${inputMessage.trim()} ✅`
						};
						try {
							await api.post(`/messagescomentnt/${ticketId}`, message3);
						} catch (err) {
							toastError(err);
						}
						setInputMessage(""); 
						setReplyingMessage(null);
						setShowEmoji(false);
						setLoading(false);
						return;  
				  } else{
					  setLoading(true);
					   const message4 = {
						read: 1,
						fromMe: true,
						mediaUrl: "",
						body: `*${user?.name}:*\n${inputMessage.trim()} ❌`
						};
						try {
							await api.post(`/messagescomentnt/${ticketId}`, message4);
						} catch (err) {
							toastError(err);
						}
					  setInputMessage(""); 
						setReplyingMessage(null);
						setShowEmoji(false);
						setLoading(false);
						return;
				  }
			
		}
		// fin new
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
		//console.log("ticketId: ",ticketId);
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
		  let userId = user.id;
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1), userId },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  if (medias.length > 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
		<IconButton
		  aria-label="send-upload"
		  component="span"
		  onClick={handleUploadMedia}
		  disabled={loading}
		>
		  <SendIcon className={classes.sendMessageIcons} />
		</IconButton>
      </Paper>
    );
  else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        {replyingMessage && renderReplyingMessage(replyingMessage)}
        <div className={classes.newMessageBox}>
          <Hidden only={["sm", "xs"]}>
            <IconButton
              aria-label="emojiPicker"
              component="span"
              disabled={loading || recording || ticketStatus !== "open"}
              onClick={(e) => setShowEmoji((prevState) => !prevState)}
            >
              <MoodIcon className={classes.sendMessageIcons} />
            </IconButton>
            {showEmoji ? (
              <div className={classes.emojiBox}>
                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                  <Picker
                    perLine={16}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                  />
                </ClickAwayListener>
              </div>
            ) : null}

				<input
				  multiple
				  type="file"
				  id="upload-button"
				  disabled={loading || recording || ticketStatus !== "open" || !useAttach}
				  className={classes.uploadInput}
				  onChange={handleChangeMedias}
				/>

				<label htmlFor="upload-button">
				  <IconButton
					aria-label="upload"
					component="span"
					disabled={loading || recording || ticketStatus !== "open" || !useAttach}
				  >
					<AttachFileIcon className={classes.sendMessageIcons} />
				  </IconButton>
				</label>

            <FormControlLabel
              style={{ marginRight: 7, color: "gray" }}
              label={i18n.t("messagesInput.signMessage")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={signMessage}
                  onChange={(e) => {
                    setSignMessage(e.target.checked);
                  }}
                  name="showAllTickets"
                  color="primary"
                />
              }
            />
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <MoreVert></MoreVert>
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
            >
              <MenuItem onClick={handleMenuItemClick}>
                <IconButton
                  aria-label="emojiPicker"
                  component="span"
                  disabled={loading || recording || ticketStatus !== "open"}
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <MoodIcon className={classes.sendMessageIcons} />
                </IconButton>
              </MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  id="upload-button"
                  disabled={loading || recording || ticketStatus !== "open" || !useAttach}
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={loading || recording || ticketStatus !== "open" || !useAttach}
                  >
                    <AttachFileIcon className={classes.sendMessageIcons} />
                  </IconButton>
                </label>
              </MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <FormControlLabel
                  style={{ marginRight: 7, color: "gray" }}
                  label={i18n.t("messagesInput.signMessage")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={signMessage}
                      onChange={(e) => {
                        setSignMessage(e.target.checked);
                      }}
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              </MenuItem>
            </Menu>
          </Hidden>
          <div className={classes.messageInputWrapper}>
            <InputBase
              inputRef={(input) => {
                input && input.focus();
                input && (inputRef.current = input);
              }}
              className={classes.messageInput}
              placeholder={
                ticketStatus === "open"
                  ? i18n.t("messagesInput.placeholderOpen")
                  : i18n.t("messagesInput.placeholderClosed")
              }
              multiline
              maxRows={5}
              value={inputMessage}
              onChange={handleChangeInput}
              disabled={recording || loading || ticketStatus !== "open"}
              onPaste={(e) => {
                ticketStatus === "open" && handleInputPaste(e);
              }}
              onKeyPress={(e) => {
                if (loading || e.shiftKey) return;
                else if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            {typeBar ? (
              <ul className={classes.messageQuickAnswersWrapper}>
                {quickAnswers.map((value, index) => {
                  return (
                    <li
                      className={classes.messageQuickAnswersWrapperItem}
                      key={index}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => handleQuickAnswersClick(value.message)}>
                        {`${value.shortcut} - ${value.message}`}
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
          {inputMessage ? (
            <IconButton
              aria-label="sendMessage"
              component="span"
              onClick={handleSendMessage}
              disabled={loading}
            >
              <SendIcon className={classes.sendMessageIcons} />
            </IconButton>
          ) : recording ? (
            <div className={classes.recorderWrapper}>
              <IconButton
                aria-label="cancelRecording"
                component="span"
                fontSize="large"
                disabled={loading}
                onClick={handleCancelAudio}
              >
                <HighlightOffIcon className={classes.cancelAudioIcon} />
              </IconButton>
              {loading ? (
                <div>
                  <CircularProgress className={classes.audioLoading} />
                </div>
              ) : (
                <RecordingTimer />
              )}

              <IconButton
                aria-label="sendRecordedAudio"
                component="span"
                onClick={handleUploadAudio}
                disabled={loading}
              >
                <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
              </IconButton>
            </div>
          ) : useMic ? (
            <IconButton
              aria-label="showRecorder"
              component="span"
              disabled={loading || ticketStatus !== "open"}
              onClick={handleStartRecording}
            >
              <MicIcon className={classes.sendMessageIcons} />
            </IconButton>
          ) : (
              <div></div>
            )
		}
        </div>
      </Paper>
    );
  }
};


function getRandomInteger(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

function uuidv4() {
  var num = getRandomInteger(1, 999999999)
   var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
	dt = new Date().getTime();
    var uuid2 = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid + uuid2 + num.toString();
}


export { MessageInput, uuidv4};
